<script>
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import loadingCircle from '@/js/components/loading-circle.vue'

// import Usa from '@/js/maps/us'
import UsaCanada from '@/js/maps/usacanada'
import UsaStatesCaProvincesName from '@/usastates-caprovinces-names.json'
import CheckboxSvgMap from '@/js/components/checkbox-svg-map.vue'

export default {
    name: 'DotstateMap',
    components: {
        LoadingCircle: loadingCircle,
        CheckboxSvgMap,
        Treeselect,
    },
    props: ['purlProp', 'statesProp', 'statefilterProp'],
    data() {
        return {
            UsaCanada,
            purl: null,
            states: [],
            stateNames: {},
            stateData: null,
            stateFilterData: null,
            selectedState: null,
            currentSelectedState: '',
            currentSelectedResults: null,
            selectedStates: [],
            selectedProductLines: null,
            productLineData: null,
            productLinesTest: [
                {
                    id: 'Chemicals',
                    label: 'Chemicals',
                    children: [{
                        id: 'Epoxies',
                        label: 'Epoxies',
                        children: [{
                            id: 'anchoring',
                            label: 'anchoring',
                        }, {
                            id: 'bonder',
                            label: 'bonder',
                        }],
                    },
                        {
                            id: 'Liquids',
                            label: 'Liquids',
                        }, {
                            id: 'Powders',
                            label: 'Powders',
                        }],
                },
                {
                    id: 'Accessories',
                    label: 'Accessories',
                    children: [{},
                        {
                            id: 'Wall Forming',
                            label: 'Wall Forming',
                        }, {
                            id: 'Bridge Deck',
                            label: 'Bridge Deck',
                        }],
                },
            ],
            allProductList: null,
            selectedProducts: [],
            productSelection: null,
            productList: null,
            pointedLocation: null,
            tooltipStyle: null,
            focusedLocation: null,
            selectedLocations: [],
            isError: false,
            errorMessage: null,
            isSelecting: false,
        }
    },
    mounted() {
        
        this.purl = this.purlProp
        // this.productLineData = this.productLinesProp;
        this.stateData = this.statesProp.sort((a, b) => a.id.localeCompare(b.id))
        this.stateNames = UsaStatesCaProvincesName
        // console.log('usa canada', this.UsaCanada)
        const updatedLocations = this.UsaCanada.locations.map(element => ({
            ...element,
            name: this.stateNames[element.id],
        }))
        this.UsaCanada.locations = updatedLocations
        if (this.statefilterProp) {
            
            var prop = this.statefilterProp.toUpperCase();
            console.log('got state filter prop', this.statefilterProp, prop)
            var result = this.stateData.find(obj => {
                return obj.label.includes(prop)
            })
            
            console.log('got filter result', result, this.stateData);
            if (result) {
                this.selectedState = result.id;
                this.handleStateSelect(result)
            }
            
        }

        // this.allProductList = this.stateData.map((sd) => sd.products).flat();
        // console.log('init all product list', this.allProductList);
        // for (let [key, value] of Object.entries(this.stateNames)) {
        // 	this.states.push({ label: value, id: key });
        // }

        // console.log('dotstate-map view mounted', this.statefilterProp, this.stateData, this.stateNames)
    },
    methods: {
        handleChange(selectedLocations) {
            var slId = selectedLocations.slice(-1)[0];
            //console.log('handle change', slId)
            this.isSelecting = true
            this.selectedStates = [];
            this.selectedStates.push(slId)

            const snode = this.$refs.stateTree.forest.nodeMap[slId]
            if (snode)
                this.$refs.stateTree.select(snode)
            this.isSelecting = false

            //this.getFilteredProducts()
        },
        handleStateSelect(stateSelect) {
            if (!this.isSelecting) {
                console.log('handle state select', stateSelect, this.selectedState)
                this.selectedLocations = []
                this.selectedLocations.push(this.selectedState)
                this.selectedStates = []
                this.selectedStates.push(this.selectedState)
                //this.addParamsToLocation({ state: this.selectedState })
                this.getFilteredProducts()
            }
        },
        handleFilterProp(stateFilter) {
                this.selectedState = 
                this.selectedLocations = []
                this.selectedLocations.push(this.selectedState)
                this.selectedStates = []
                this.selectedStates.push(this.selectedState)
                this.getFilteredProducts()
            
        },
        handleProductLineSelect(plineSelect) {
            if (!this.isSelecting) {
                //console.log('handle pline select', plineSelect, this.selectedProductLines)
                this.plineSelect = this.electedProductLines
                this.getFilteredProducts()
            }
        },
        toTitleCase(str) {
            str = str.replace('-', ' ');
            return str.replace(
            /\w\S*/g,
            text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
            );
        },
        addParamsToLocation(params) {
            history.pushState(
                {},
                null,
                this.$route.path +
                '?' +
                Object.keys(params)
                    .map(key => {
                        return (
                            encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                        )
                    })
                    .join('&')
            )
        },
        addUniqueProduct(data) {
            let index = -1

            for (let i = 0; i < this.productList.length; i++) {
                if (this.productList[i].id === data.id)
                    index = i
            }

            if (index > -1)
                this.productList[index] = data
            else
                this.productList.push(data)
        },
        getLocationName(node) {
            return node && node.attributes.name.value
        },
        getSelectedLocationName(map, locationId) {
            // console.log('getselectedlocationame', map, locationId)
            return locationId && map.locations.find(location => location.id === locationId).name
        },
        pointLocation(event) {
            this.pointedLocation = this.getLocationName(event.target)
        },
        unpointLocation(event) {
            this.pointedLocation = null

            this.tooltipStyle = {display: 'none'}
        },
        moveOnLocation(event) {
            this.tooltipStyle = {
                display: 'block',
                top: `${event.clientY + 10}px`,
                left: `${event.clientX - 100}px`,
            }
            // console.log('mouse move on location event', event, this.tooltipStyle)
        },
        focusLocation(event) {
            this.focusedLocation = this.getLocationName(event.target)
        },
        blurLocation(event) {
            this.focusedLocation = null
        },
        productLineNormalizer(p) {
            return {
                id: p.Id,
                label: p.Label,
                children: p.Children,
            }
        },
        getFilteredProducts() {
            this.currentSelectedState = this.selectedStates[0];
            console.log('calling api with selections', this.selectedProducts, this.selectedStates, this.selectedProductLines)
            const loader = this.$loading.show()
            this.$http
                .post('/authapi/DOTApproval/Filter', {
                    productIds: this.selectedProducts,
                    productLineTaxaIds: this.selectedProductLines,
                    stateTaxaIds: this.selectedStates,
                })
                .then(function (response) {
                    if (response.data) {
                        //console.log('got filter dot approval response', response);
                        if (response.data.Status && response.data.Status === 'failed') {
                            this.errorMessage = response.data.Message
                            this.isError = true
                            if (response.data.Data && response.data.Data.stacktrace)
                                console.log(response.data.Data.stacktrace)

                            this.$toastify.toastError(this.errorMessage)
                        } else {
                            this.isError = false
                            this.errorMessage = ''
                            const data = response.body
                            // console.log('data', data)
                            this.productList = data.Products
                            this.productLineData = data.ProductsByProductLine
                            this.currentSelectedResults = this.productList.length;
                        }
                    } else {
                        this.errorMessage = response.Message
                        this.$toastify.toastError(this.errorMessage)

                        this.isError = true
                    }
                    loader.hide()
                })
        },
    },
}
</script>

<template>
    <div class="container-fluid">
        <h5>Select a Region then see list of products below</h5>
        <div class="row row-cols-1 row-cols-md-2 g-2 p-0 m-0">
            <div class="container" style="max-width: 80%">
                <CheckboxSvgMap
                    v-model="selectedLocations" :map="UsaCanada" @blur="blurLocation"
                    @change="handleChange"
                    @focus="focusLocation" @mousemove="moveOnLocation" @mouseout="unpointLocation"
                    @mouseover="pointLocation"
                />
            </div>
            <div :style="tooltipStyle" class="svgmap__block__map__tooltip">
                {{ pointedLocation }}
            </div>
        </div>

        <div class="row row-cols-1 row-cols-md-2 g-2 p-0 m-0">
            <div class="col-md6">
                <div class="svgmap__block__info__item">
                    <h5>Select a Region then see list of products below</h5>
                    <Treeselect
                        ref="stateTree" v-model="selectedState" :flatten-search-results="true"
                        :multiple="false"
                        :options="stateData" value-consists-of="BRANCH_PRIORITY"
                        @input="handleStateSelect()"
                    />
                </div>
            </div>
            <div v-if="selectedStates && selectedStates.length > 0" class="col-md6">
                <div class="">
                    <h5>Select Category And/Or Product</h5>
                    <Treeselect
                        v-model="selectedProductLines" :default-expand-level="1"
                        :disable-branch-nodes="false"
                        :multiple="true"
                        :normalizer="productLineNormalizer"
                        :options="productLineData" no-children-text="No Products Available"
                        no-options-text="No Products Available"
                        value-consists-of="BRANCH_PRIORITY" @input="handleProductLineSelect()"
                    />
                </div>
            </div>
        </div>

        <h5>Filtered Results <span v-if="currentSelectedState">- Selected {{ currentSelectedState }} - {{ currentSelectedResults  }} results</span>
        </h5>

        <div class="row row-cols-1 row-cols-md-2 g-2 p-0 m-0">
            <div v-if="!productList || (productList != null & productList.length === 0)">
                <div class="card shadow rounded-1 px-2 mt-1">
                    <div class="card-body">
                        <p>No Products Matching Filters above.</p>
                    </div>
                </div>
            </div>
            <div v-else class="table-responsive">
                <table class="table table-sm table-striped table-hover w-auto mw-100">
                    <thead>
                    <tr>
                        <th scope="col">
                            Product
                        </th>
                        <th scope="col">
                            Document Type
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="product in productList" :key="product.Sku">
                        <th scope="row" style="width:40%;">
                            <a :href="product.UrlName" class="font-weight-bolder text-secondary">{{
                                product.Title }}</a>
                        </th>
                        <td style="width:60%;">
                            <template v-if="product.ProductLinks.ShowTechDataSheets">
                  <span v-for="d in product.ProductLinks.TechDataSheets.Links" :key="d.Url">
                    <a :href="d.Url" rel="noopener noreferrer" target="_blank">TDS</a>
                  </span>
                            </template>
                            <span
                                v-if="product.ProductLinks.ShowTechDataSheets && product.ProductLinks.ShowMSDs">|</span>
                            <template v-if="product.ProductLinks.ShowMSDs">
                  <span v-for="d in product.ProductLinks.MSDs.Links" :key="d.Url">
                    <a :href="d.Url" rel="noopener noreferrer" target="_blank">SDS</a>
                  </span>
                            </template>
                        </td>
                        <!-- <h4 class="dsm-flex mx-1">
                        <a class="font-weight-bolder text-secondary" :href="product.UrlName">{{ product.Title }}</a>
                      </h4>
                      <div class="dsm-flex mx-1">
                        <div v-if="product.ProductLinks.ShowTechDataSheets" style="list-style:none;margin:1px;padding:1px;">
                          <span v-for="d in product.ProductLinks.TechDataSheets.Links" :key="d.Url">
                            <a :href="d.Url">TDS</a>
                          </span>
                        </div>
                        <span v-if="product.ProductLinks.ShowTechDataSheets && product.ProductLinks.ShowMSDs">|</span>
                        <div v-if="product.ProductLinks.ShowMSDs" style="list-style:none;margin:1px;padding:1px;">
                          <span v-for="d in product.ProductLinks.MSDs.Links" :key="d.Url">
                            <a :href="d.Url">SDS</a>
                          </span> -->
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
/* Maps examples */

.card {
    background-color: #f3f3f3;
}

.svgmap {
    width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    text-align: center;

    &__title {
        margin-bottom: 80px;
        padding-bottom: 40px;
        border-bottom: 1px dotted black;
        font-size: 24px;
    }

    &__block {
        margin-bottom: 80px;
        padding-bottom: 80px;
        border-bottom: 1px dotted black;

        &__title {
            margin-bottom: 40px;
            font-size: 20px;
        }

        &__info {
            display: inline-block;
            vertical-align: top;
            width: 450px;
            text-align: left;

            &__item {
                margin-bottom: 20px;
            }
        }

        &__map {
            display: inline-block;
            width: 800px;

            &__tooltip {
                position: fixed;
                width: 125px;
                padding: 10px;
                border: 1px solid darkgray;
                background-color: white;
                text-align: center;
            }
        }
    }
}

.dsm-flex {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;
}

.dsm-box {
    padding: 20px;
    border: 1px solid black;
    width: 100px;
    height: 100px;
    margin: 10px;
}

.dsm-row {
    display: flex;
    flex-direction: row;
}
</style>
