﻿import { initializeComponent } from "@/js/functions/init";
import dropdownMenu from "@/js/components/dropdown-menu.vue";
import search from '../components/search.vue';
import currencySelector from '../components/currency-selector.vue';

initializeComponent("category-navigation", init);

export default function init(rootElement) {
    const scriptElement = rootElement.querySelector('script[data-items]');
    const items = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).items;
    new Vue({
        name: 'category-navigation',
        el: '#' + rootElement.id,
        props: {
            showFilter: {
                type: Boolean,
                default: false
            },
            purl: {
                type: String,
                default: false
            }
        },
        data: {
            items: items,
            numberOfItemsInBasket: 5
        },
        components: {
            dropdownMenu,
            search,
            currencySelector
        },
        methods: {
            toggleSearchBar: function() {

                var searchComponent = this.$refs['search'];
                if (searchComponent) {
                    searchComponent.toggleSearchBar();
                }
            },
            toggleFilter: function() {

                this.showFilter = !this.showFilter;
            }
        }
    });
}