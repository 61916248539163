import Popper from 'vue-popperjs'
import { mapState } from 'vuex'

import { initializeComponent } from '@/js/functions/init'
import { debounce } from '@/js/functions/debounce'
import checkoutNavigation from '@/js/components/checkout-navigation.vue'
import inputFieldNumeric from '@/js/components/input-field-numeric.vue'
import inputFieldNumericStep from '@/js/components/input-field-numeric-step.vue'
import inputFieldDate from '@/js/components/input-field-date.vue'
import inputFieldText from '@/js/components/input-field-text.vue'
import inputFieldTextarea from '@/js/components/input-field-textarea.vue'
// import PopoverHover from "@/js/components/popover-hover.vue";
import 'vue-popperjs/dist/vue-popper.css'

import store from '@/js/store'

initializeComponent('cart', init)

const cartlistKey = 'ds_ucommerce_cartList'

const cartlistItem = function (
  sku,
  variantSku,
  listPrice,
  userPrice,
  price,
  qty,
  packaging,
  minOrder,
  orderFactor,
) {
  this.Sku = sku
  this.VariantSku = variantSku
  this.ListPrice = listPrice
  this.UserPrice = userPrice
  this.Price = price
  this.Quantity = qty
  this.Packaging = packaging
  this.MinOrder = minOrder
  this.OrderFactor = orderFactor

  this.equals = function (other) {
    return other.Sku === this.Sku && other.VariantSku === this.VariantSku
  }
}

export default function init(rootElement) {
  new Vue({
    name: 'cart',
    el: `#${rootElement.id}`,
    store,
    components: {
      CheckoutNavigation: checkoutNavigation,
      InputFieldNumeric: inputFieldNumeric,
      InputFieldNumericStep: inputFieldNumericStep,
      InputFieldDate: inputFieldDate,
      InputFieldText: inputFieldText,
      InputFieldTextarea: inputFieldTextarea,
      // PopoverHover
      Popper,
    },
    props: ['purl'],
    data() {
      return {
        model: null,
        inputVoucher: null,
        currentCustomer: null,
        disabledDates: {},
        blackoutDates: [],
        isError: false,
        errorMessage: null,
        shippedDateInput: null,
        hasVoucher: false,
      }
    },
    computed: {
      ...mapState(['triggerSubmit']),
      isCustomerDistributor() {
        return this.$store.getters.isCustomerDistributor
      },
      availableCustomers() {
        return this.$store.getters.availableCustomers
      },
      activeCustomer() {
        return this.$store.getters.activeCustomer
      },
      activeShipto() {
        return this.$store.getters.activeShipto
      },
    },
    watch: {
      triggerSubmit() {
        this.submit((success) => {
          if (success) {
            this.loader.hide()
            this.$store.dispatch('widgetSubmitted')
          }
        })
      },
      activeCustomer(newCustomer, oldCustomer) {
        this.currentCustomer = newCustomer
        // this.updateCartItems()

        if (this.model && this.model.OrderLines) {
          let needsUpdate = true
          for (const item of this.model.OrderLines) {
            const orderlineId = item.OrderLineId

            if (item.PrivateLabelCustomers) {
              let canOderPrivateLabelItem = false
              for (const customer in item.PrivateLabelCustomers) {
                if (newCustomer === customer.CustomerId)
                  canOderPrivateLabelItem = true
              }

              if (!canOderPrivateLabelItem) {
                item.Quantity = 0
                needsUpdate = true
                const warningMessage
                                    = `Cannot order private label item ${
                                     item.VariantSku
                                     } for your current selected customer id ${
                                     newCustomer}`
                this.$toastify.toastWarning(warningMessage)
              }
            }
          }

          if (needsUpdate)
            this.updateCartItems(false)
        }
      },
      activeShipto(newShipTo, oldShipTo) {
        // this.updateCartItems(false)
      },
    },
    created() {
      this.$store.commit('vuecreated', 'cart')
      this.getCart()
    },
    filters: {
      toCurrency: function (value) {
        if (!value) return ''
        
        if (typeof value !== "number")
          return value;
        
        return `$${value.toFixed(3)}`
      }
    },
    methods: {
      updateCartItemsDebounced: debounce(function (e) {
        this.updateCartItems()
      }, 1500),
      updateCartItems(isQuantityUpdate) {
        const model = this.model
        const orderlineKeyValue = []
        const store = this.$store
        const loader = this.$loading.show()

        let activeCustomer = this.activeCustomer
        if (!activeCustomer)
          activeCustomer = this.currentCustomer

        for (const item of model.OrderLines) {
          const orderlineId = item.OrderLineId
          const orderlineQty = item.Quantity
          const orderlineMinOrder = item.MinOrder
          const orderlineOrderFactor = item.OrderFactor
          const orderlineNote = item.Note
          const requestedShipDate = item.RequestedShipDate
          const comments = item.Comments
          const variantSku = item.VariantSku
          const sku = item.Sku
          const vouchers = item.Vouchers
          const currentKeyValue = {
            orderlineId,
            orderlineQty,
            orderlineMinOrder,
            orderlineOrderFactor,
            orderlineNote,
            requestedShipDate,
            comments,
            variantSku,
            sku,
            vouchers,
          }
          orderlineKeyValue.push(currentKeyValue)
        }

        this.$http
          .post(`${this.purl}/uc/checkout/cart/update-basket`, {
            RefreshBasket: orderlineKeyValue,
            ActiveCustomer: activeCustomer,
            Vouchers: model.Vouchers,
            Email: model.Email,
            IsQuantityUpdate: isQuantityUpdate,
          })
          .then(function (response) {
            if (response.data) {
              console.log('got cart update basket response', response)
              if (response.data.Status && response.data.Status === 'warning') {
                this.errorMessage = response.data.Message
                this.isError = true

                if (this.model.Vouchers)
                  this.model.Vouchers.splice(-1)

                this.$toastify.toastWarning(this.errorMessage)

                const data = response.data.Data
                const updatedFields = [
                  'WeightTotal',
                  'SubTotal',
                  'TaxTotal',
                  'DiscountTotal',
                  'OrderTotal',
                  'IsLimitedQuantity',
                ]
                const orderLineArray = []

                for (const field of updatedFields)
                  model[field] = data[field]

                for (const updatedItem of data.OrderLines) {
                  for (const currentItem of model.OrderLines) {
                    if (currentItem.OrderLineId === updatedItem.OrderlineId) {
                      currentItem.UserPriceDisplay = updatedItem.UserPrice
                      orderLineArray.push(
                        Object.assign({}, currentItem, updatedItem),
                      )
                    }
                  }
                }
                console.log('updating model orderlines warning', orderLineArray)
                model.OrderLines = orderLineArray
                store.commit('update')
              }
              else if (response.data.Status && response.data.Status === 'failed') {
                this.errorMessage = response.data.Message
                this.isError = true
                if (response.data.Data && response.data.Data.stacktrace)
                  console.log(response.data.Data.stacktrace)

                this.$toastify.toastError(this.errorMessage)
              }
              else {
                this.isError = false
                this.errorMessage = ''
                const data = response.data.Data
                const updatedFields = [
                  'WeightTotal',
                  'SubTotal',
                  'TaxTotal',
                  'DiscountTotal',
                  'OrderTotal',
                  'IsLimitedQuantity',
                ]
                const orderLineArray = []

                for (const field of updatedFields)
                  model[field] = data[field]

                for (const updatedItem of data.OrderLines) {
                  for (const currentItem of model.OrderLines) {
                    if (currentItem.OrderLineId === updatedItem.OrderlineId) {
                      orderLineArray.push(
                        Object.assign({}, currentItem, updatedItem),
                      )
                    }
                  }
                }

                this.inputVoucher = null
                console.log('updating model orderlines no warning', orderLineArray)
                model.OrderLines = orderLineArray
                store.commit('update')
              }
            }
            else {
              this.errorMessage = response.Message
              this.$toastify.toastError(this.errorMessage)

              this.isError = true
            }
            loader.hide()
          })
      },
      isDisabledDate(date) {
        if (this.disabledDates) {
          if (this.disabledDates.from) {
            if (date < this.disabledDates.from)
              return true
          }

          if (this.disabledDates.to) {
            if (date > this.disabledDates.to)
              return true
          }
        }
        return false
      },
      checkShipDate(shipDateInput) {
        const shipDate = new Date(`${shipDateInput}T00:00:00`)
        const now = new Date(new Date().toLocaleDateString())
        if (shipDate && this.model) {
          now.setHours(0, 0, 0, 0)
          shipDate.setHours(
            now.getHours(),
            now.getMinutes(),
            now.getSeconds(),
            now.getMilliseconds(),
          )
          const diff = shipDate - now
          if (diff === 0) {
            this.$toastify.toastWarning(
              'Requested ship date on same day may not be available.',
            )
          }
          else if (diff < 0) {
            return false
          }
        }

        return true
      },
      setShippedDate($event, isSingle) {
        const value = isSingle ? $event : this.shippedDateInput
        const isValidShipDate = this.checkShipDate(value)
        if (isValidShipDate && this.model && !isSingle) {
          for (const item of this.model.OrderLines)
            item.RequestedShipDate = this.shippedDateInput
        }
        this.updateCartItems()
      },
      updateCartUI(count) {
        if (count === 0)
          count = ''
        else count = `(${count})`

        // document.querySelector('.cartlistCount').innerHTML = count;
      },
      findIndex(list, cartlistItem) {
        let index = -1
        for (let i = 0; i < list.length; i++) {
          if (
            list[i].Sku === cartlistItem.Sku
                        && list[i].VariantSku === cartlistItem.VariantSku
          ) {
            index = i
            break
          }
        }
        return index
      },
      removeFromList(
        sku,
        variantSku,
        listPrice,
        userPrice,
        price,
        qty,
        packaging,
        minOrder,
        orderFactor,
      ) {
        const list = JSON.parse(localStorage.getItem(cartlistKey)) || []
        const removeItem = new cartlistItem(
          sku,
          variantSku,
          listPrice,
          userPrice,
          price,
          qty,
          packaging,
          minOrder,
          orderFactor,
        )

        if (list != null) {
          const pIndex = this.findIndex(list, removeItem)

          if (pIndex > -1)
            list.splice(pIndex, 1)

          localStorage.setItem(cartlistKey, JSON.stringify(list))
        }

        this.updateCartUI(list.length)
      },
      removeCartItem(itemId) {
        const model = this.model
        const store = this.$store
        const loader = this.$loading.show()
        const selector = `[data-order-line-id="${itemId}"]`
        const item = document.querySelector(selector)

        // Google Analytics
        window.dataLayer = window.dataLayer || []
        let productQuantity = 1
        const parsedQuantity = parseInt(item.dataset.quantity)

        if (parsedQuantity)
          productQuantity = parsedQuantity

        const productData = {
          id: item.dataset.sku,
          name: item.dataset.name,
          category: '',
          variant: item.dataset.variantSku,
          brand: item.dataset.brand,
          quantity: productQuantity,
          dimension3: 'Ecommerce',
          metric5: 12,
          metric6: 1002,
        }

        window.dataLayer.push({
          event: 'eec.remove',
          ecommerce: {
            add: {
              actionField: {
                list: 'Shopping cart',
              },
              products: [productData],
            },
          },
        })

        this.removeFromList(
          item.getAttribute('data-sku'),
          item.getAttribute('data-variant-sku'),
          item.getAttribute('data-list-price'),
          item.getAttribute('data-user-price'),
          item.getAttribute('data-price'),
          item.getAttribute('data-quantity'),
          item.getAttribute('data-packaging'),
          item.getAttribute('data-min-order'),
          item.getAttribute('data-order-factor'),
        )

        this.$http
          .post(`${this.purl}/uc/checkout/cart/remove-orderline`, {
            orderlineId: itemId,
          })
          .then(function (response) {
            if (response.data) {
              if (response.data.Status && response.data.Status === 'failed') {
                this.errorMessage = response.data.Message
                this.isError = true
                if (response.data.Data && response.data.Data.stacktrace)
                  console.log(response.data.Data.stacktrace)

                this.$toastify.toastError(this.errorMessage)
              }
              else {
                this.isError = false
                const data = response.data

                if (data.OrderLines.length === 0) {
                  model.OrderLines = []
                  store.commit('update')
                }
                else {
                  const updatedFields = [
                    'WeightTotal',
                    'SubTotal',
                    'TaxTotal',
                    'DiscountTotal',
                    'OrderTotal',
                    'IsVocCompliant',
                    'IsConstrained',
                    'IsLimitedQuantity',
                  ]

                  for (const field of updatedFields)
                    model[field] = data[field]

                  const updatedItems = []

                  // generate new set without the removed item
                  for (const item of model.OrderLines) {
                    if (item.OrderLineId != itemId)
                      updatedItems.push(item)
                  }

                  // for (let updatedItem of data.OrderLines) {
                  //     for (let currentItem of model.OrderLines) {
                  //         if (currentItem.OrderLineId === updatedItem.OrderlineId) {
                  //             orderLineArray.push(Object.assign({}, currentItem, updatedItem));
                  //         }
                  //     }
                  // }

                  model.OrderLines = updatedItems

                  if (this.model.PromoCodes && this.model.PromoCodes.length) {
                    this.hasVoucher = true
                    this.model.Vouchers = this.model.PromoCodes
                  }
                  else {
                    this.hasVoucher = false
                    this.model.Vouchers = this.model.PromoCodes
                  }

                  store.commit('update')
                }
              }
            }
            else {
              this.$toastify.toastWarning(response)
            }

            loader.hide()
          })
      },
      applyVoucher() {
        const model = this.model
        if (this.inputVoucher) {
          if (model.Vouchers && model.Vouchers.length > 0) {
            if (!model.Vouchers.includes(this.inputVoucher))
              model.Vouchers.push(this.inputVoucher)
          }
          else {
            model.Vouchers = [this.inputVoucher]
          }
        }
        this.updateCartItems()
      },
      removeVoucher(vouchersTitle) {
        if (vouchersTitle) {
          this.model.Vouchers = this.model.Vouchers.filter((v) => {
            return v !== vouchersTitle
          })

          this.updateCartItems()
        }
      },
      submit(fieldName, doNotHighlight, callback) {
        const loader = this.$loading.show()
        const model = this.model
        const orderlineKeyValue = []

        for (const item of model.OrderLines) {
          const orderlineId = item.OrderLineId
          const orderlineQty = item.Quantity
          const orderlineMinOrder = item.MinOrder
          const orderlineOrderFactor = item.OrderFactor
          const orderlineNote = item.Note
          const requestedShipDate = item.RequestedShipDate
          const comments = item.Comments
          const variantSku = item.VariantSku
          const sku = item.Sku

          const currentKeyValue = {
            orderlineId,
            orderlineQty,
            orderlineMinOrder,
            orderlineOrderFactor,
            orderlineNote,
            requestedShipDate,
            comments,
            variantSku,
            sku,
          }

          orderlineKeyValue.push(currentKeyValue)
        }

        let activeCustomer = this.activeCustomer
        if (!activeCustomer)
          activeCustomer = this.currentCustomer

        this.$http
          .post(`${this.purl}/uc/checkout/cart/update-basket`, {
            RefreshBasket: orderlineKeyValue,
            Email: model.Email,
            ActiveCustomer: activeCustomer,
            Vouchers: model.Vouchers,
            IsCheckout: true,
          })
          .then((response) => {
            // This is handled below
            // if (response.data.Status && response.data.Status === 'failed') {
            //    this.errorMessage = response.data.Message;
            //    if (response.data.Data && response.data.Data.stacktrace) {
            //        console.log(response.data.Data.stacktrace);
            //    }
            //    this.isError = true;

            //    this.$toastify.toastError(this.errorMessage);
            // }

            if (response.data) {
              const data = response.data
              // store.commit('update');

              if (data.Status) {
                if (fieldName && data.Data.errors) {
                  if (
                    typeof data.Data.errors[fieldName] !== 'undefined'
                                        && data.Data.errors[fieldName].length > 0
                  ) {
                    if (
                      data.Data.errors
                                            && data.Data.errors[fieldName].length
                    ) {
                      this.isError = true
                      loader.hide()
                      callback(false, data.Data.errors[fieldName])
                    }
                  }
                  else {
                    callback(true, '')
                  }
                }
                else if (data.Status === 'success') {
                  callback(true, '')
                }
                else if (data.Status === 'failed' && data.Message) {
                  loader.hide()
                  if (data.Data && data.Data.stacktrace)
                    console.log(data.Data.stacktrace)

                  this.$toastify.toastError(data.Message)
                  callback(false, data.Message)
                }
                else {
                  if (!doNotHighlight)
                    this.highlightFields(data.Data.errors)

                  this.isError = false

                  callback(false, data.Message)
                }
              }
              else {
                callback(false, '')
              }
            }
          })
      },
      continueFn(callback) {
        this.submit(null, false, callback)
      },
      highlightFields(errors) {
        if (!errors || errors.length === 0)
          return

        let message = ''

        for (const fieldName in errors) {
          for (const input of this.$children) {
            if (input.inputName === fieldName)
              input.errorMessage = errors[fieldName]

            message = message + errors[fieldName]
          }
        }
      },
      getUserPrice(orderLine) {
        return orderLine.UserPrice
      },
      getListPrice(orderLine) {
        if (this.model.IsSignedIn)
          return orderLine.ListPrice
        else
          return orderLine.UserPrice
      },
      getModel(object) {
        return JSON.stringify(object, null, '\t')
      },
      getCart() {
        const scriptElement = rootElement.querySelector('script[purl]')
        const dateNow = new Date(new Date().toLocaleDateString())
        // eslint-disable-next-line vue/no-mutating-props
        this.purl = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).purl

        const params = {}
        const basketId = this.$cookies.get('basketid')
        // check for null first
        if (basketId)
          params.basketId = basketId

        const query = Object.keys(params)
          .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
          .join('&')

        this.$http.get(`${this.purl}/uc/checkout/cart?${query}`, {}).then((response) => {
          if (
            response.data
                        && response.data.Status
                        && (response.data.Status === 'success' || response.data.Status === 'warning')
                        && response.data.Data
                        && response.data.Data.data
          ) {
            this.model = response.data.Data.data
            if (response.data.Message)
              this.$toastify.toastWarning(response.data.Message)

            if (this.model) {
              if (this.model.PromoCodes && this.model.PromoCodes.length) {
                this.hasVoucher = true
                this.model.Vouchers = this.model.PromoCodes
              }
              else {
                this.hasVoucher = false
                this.model.Vouchers = this.model.PromoCodes
              }

              if (this.model.BlackoutDates && this.model.BlackoutDates.length) {
                this.model.BlackoutDates.forEach((date) => {
                  this.blackoutDates.push(date)
                })

                this.disabledDates = {
                  to: new Date(this.model.MaxShipDate),
                  from: dateNow,
                }
              }
              else {
                const oneYearFromNow = new Date()
                oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
                this.disabledDates = {
                  to: oneYearFromNow,
                  from: dateNow,
                }
              }

              const syncCartEvent = document.createEvent('CustomEvent')
              syncCartEvent.initCustomEvent(
                'cartlistManager:syncCart',
                true,
                true,
                {},
              )
              window.dispatchEvent(syncCartEvent)
            }
          }
          else {
            if (response.data.Status && response.data.Status === 'failed') {
              this.errorMessage = response.data.Message
              if (response.data.Data && response.data.Data.stacktrace)
                console.log(response.data.Data.stacktrace)

              this.isError = true
              this.$toastify.toastError(this.errorMessage)
            }
            this.model = null
          }
        })
      },
    },
  })
}
