import { initializeComponent } from "@/js/functions/init";
import orderSubmitNavigation from "@/js/components/order-submit-navigation.vue";
import inputFieldCheckbox from "@/js/components/input-field-checkbox.vue";

import store from '@/js/store';



import { mapState } from 'vuex';

initializeComponent("checkout-overview", init);

const cartlistKey = 'ds_ucommerce_cartList';

var cartlistItem = function(sku, variantSku, price, qty) {

    this.Sku = sku;
    this.VariantSku = variantSku;
    this.Price = price;
    this.Quantity = qty;

    this.equals = function(other) {
        return other.Sku == this.Sku && other.VariantSku == this.VariantSku;
    };
};

function debounce(fn, delay) {
    var timeoutID = null
    return function() {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function() {
            fn.apply(that, args)
        }, delay)
    }
};

export default function init(rootElement) {
    new Vue({
        name: 'checkout-overview',
        el: '#' + rootElement.id,
        store,
        props: ['purl'],
        data: {
            model: null,
            isError: false,
            errorMessage: null
        },
        computed: {
            ...mapState([
                'updateIteration',
                'allowNavigate'
            ]),
            checkoutSubmitted() {
                return this.$store.getters.checkoutSubmitted;
            },
        },
        watch: {
            updateIteration: function() {
                this.fetchData();
            },
            allowNavigate: function() {
                this.submit();
            }
        },
        components: {
            orderSubmitNavigation,
            inputFieldCheckbox
        },
        methods: {
            fetchData: function() {
                this.$http.get(this.purl + '/uc/checkout/preview', {}).then((response) => {
                    if (response.data &&
                        response.data.Status &&
                        response.data.Status == 'success' &&
                        response.data.Data && response.data.Data.data) {

                        this.model = response.data.Data.data;
                    } else {
                        this.model = null;
                    }
                });
            },
            continueFn: function(callback) {
                // Prevent the default redirect action
                callback(false);

                if (this.$store.state.Ucommerce.widgets.length) {
                    this.$store.commit('triggersubmit');
                } else {
                    if (!this.checkoutSubmitted) {
                        this.submit();
                    }
                }
            },
            updateCartUI: function(count) {

                if (count == 0)
                    count = "";
                else
                    count = "(" + count + ")";

                //document.querySelector('.cartlistCount').innerHTML = count;

            },
            findIndex: function(list, cartlistItem) {
                var index = -1;
                for (var i = 0; i < list.length; i++) {
                    if (list[i]["Sku"] == cartlistItem.Sku && list[i]["VariantSku"] == cartlistItem.VariantSku) {
                        index = i;
                        break;
                    }
                }
                return index;
            },
            removeFromList: function(sku, variantSku, price, qty) {
                var list = JSON.parse(localStorage.getItem(cartlistKey)) || [];
                var removeItem = new cartlistItem(sku, variantSku, price, qty);

                if (list != null) {
                    var pIndex = this.findIndex(list, removeItem);

                    if (pIndex > -1) {
                        list.splice(pIndex, 1);
                    }
                    localStorage.setItem(cartlistKey, JSON.stringify(list));
                }

                this.updateCartUI(list.length);
            },
            submitDebounced: debounce(function(e) {
                this.submit()
            }, 5000),
            submit: function() {
                var requestData = {};
                let loader = this.$loading.show();

                if (this.model.AgreedToTermsAndConditions) {
                    requestData['AgreedToTermsAndConditions'] = true;
                    //requestData['CheckoutSubmitted'] = true;
                } else {
                    requestData['AgreedToTermsAndConditions'] = false;
                    //requestData['CheckoutSubmitted'] = false;
                }

                requestData['IsVocCompliant'] = this.model.IsVocCompliant;

                if (this.model.ReviewedVocInformation) {
                    requestData['ReviewedVocInformation'] = true;
                } else {
                    requestData['ReviewedVocInformation'] = false;
                }

                this.$http.post(this.purl + '/uc/checkout/complete-order', requestData).then((response) => {
                    if (response.data) {
                        if (response.data.Status && response.data.Status == 'failed') {
                            this.errorMessage = response.data.Message;
                            loader.hide();
                            this.$toastify.toastError(this.errorMessage);
                            this.isError = true;
                            this.$store.dispatch('checkoutReset');
                        } else {
                            this.isError = false;
                            var data = response.data;

                            if (data.Status && data.Status == 'success') {

                                // Google Analytics
                                const products = [];
                                for (let i = 0; i < this.model.OrderLines; i++) {
                                    const product = this.model.OrderLines[i];
                                    let brand = "";
                                    for (let j = 0; j < globalThis.cart.length; j++) {
                                        if (globalThis.cart[j].Sku == product.Sku && globalThis.cart[j].VariantSku == product.VariantSku) {
                                            brand = globalThis.cart[j].Brand;
                                            break;
                                        }
                                    }
                                    products.push({
                                        id: product.Sku,
                                        name: product.ProductName,
                                        category: "",
                                        variant: product.VariantSku,
                                        brand: brand,
                                        quantity: product.Quantity,
                                        price: product.Price,
                                        dimension3: 'Ecommerce',
                                        metric5: 12,
                                        metric6: 1002
                                    });
                                }
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    event: 'eec.purchase',
                                    ecommerce: {
                                        currencyCode: 'EUR',
                                        purchase: {
                                            actionField: {
                                                id: this.model.OrderId,
                                                affiliation: null,
                                                revenue: this.model.OrderTotal,
                                                tax: this.model.TaxTotal,
                                                shipping: this.model.ShipmentAmount,
                                                coupon: "",
                                            },
                                            products: products,
                                        }
                                    }
                                });

                                //loop through cartlistitems and remove them from local storage
                                var selector = '.cartListItem';
                                var items = document.querySelectorAll(selector);
                                for (var i = 0; i < items.length; i++) {
                                    var item = items[i];
                                    this.removeFromList(
                                        item.getAttribute("data-sku"),
                                        item.getAttribute("data-variant-sku"),
                                        item.getAttribute("data-price"),
                                        item.getAttribute("data-quantity")
                                    );
                                }

                                this.$store.dispatch('checkoutSubmitted', data.Data.CheckoutTransactionId);

                                // If the transaction is completed, redirect the user the the payment URL
                                if (this.model.PaymentSummary.Nonce) {
                                    // Payment info has already been captured, need to post nonce to payment processor URL
                                    // Payment processor relies on HTTP context, thus another request
                                    this.updateFormAndPost(data.Message, { payment_method_nonce: this.model.PaymentSummary.Nonce })
                                } else {
                                    location.href = data.Message;
                                }
                            } else {
                                this.$toastify.toastError('Order not complete! Order did not process successfully!');
                                console.error('Order not complete!');
                                loader.hide();
                                this.$store.dispatch('checkoutReset');
                            }
                        }
                    } else {
                        this.$toastify.toastError('Order not complete! Did not recieve response from server!');
                        console.error('Order not complete!');
                        loader.hide();
                        this.$store.dispatch('checkoutReset');

                    }
                });
            },
            updateFormAndPost: function(url, params) {
                // Sitefinity wraps everything in a form.
                // Hijack the form to post payment token to payment processing URL
                const form = document.getElementById('aspnetForm');

                if (!form) {
                    console.error('Did not find aspnetForm');
                    return;
                }

                form.method = "post";
                form.action = url;

                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        const hiddenField = document.createElement('input');
                        hiddenField.type = 'hidden';
                        hiddenField.name = key;
                        hiddenField.value = params[key];

                        form.appendChild(hiddenField);
                    }
                }

                form.submit();
            }
        },
        created: function() {
            var scriptElement = rootElement.querySelector('script[purl]');
            this.purl = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).purl;
            this.fetchData();
        }
    });
}