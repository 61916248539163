import { initializeComponent } from "@/js/functions/init";
import checkoutNavigation from "@/js/components/checkout-navigation.vue";
import inputFieldAddress from "@/js/components/input-field-address.vue";
import store from '../store';
import { mapState } from 'vuex';
import { INIT_SHIPTO_LIST } from "../store/types";

initializeComponent("address", init);

export default function init(rootElement) {
    new Vue({
        name: 'address',
        el: '#' + rootElement.id,
        store,
        props: ['purl'],
        data: {
            model: null,
            selectedShipTo: null,
            selectedCustomer: null,
        },
        computed: {
            ...mapState([
                'triggerSubmit'
            ]),
            isShipToDistributor() {
                return this.$store.getters.isShipToDistributor;
            },
            availableShipTos() {
                return this.$store.getters.availableShipTos;
            },
            activeShipToName() {
                return this.$store.getters.activeShipToName;
            },
            activeShipTo() {
                return this.$store.getters.activeShipTo;
            },
            availableCustomers() {
                return this.$store.getters.availableCustomers;
            },
            activeCustomer() {
                return this.$store.getters.activeCustomer;
            },
        },
        watch: {
            triggerSubmit: function() {
                this.submit(null,
                    false,
                    (success) => {
                        if (success) {
                            this.$store.dispatch('widgetSubmitted');
                        }
                    });
            },
            //activeCustomer(newCustomer, oldCustomer) {
            //    // I'm not sure this function is needed. Customer picker is read-only after the cart.
            //    this.selectedCustomer = this.availableCustomers.find(item => item.ErpId === newCustomer);                  
            //    console.log('selected customer', this.selectedCustomer);
            //},
            activeShipTo(newShipTo, oldShipTo) {   
                this.selectedShipTo = this.availableShipTos.find(item => item.ShipToId === newShipTo);                  
                console.log('selected shipto', this.selectedShipTo);
            },
            //selectedCustomer: function (newCustomer, oldCustomer) {
            //    console.log('filling in customer', newCustomer);

            //    if (newCustomer) {
            //        var companyName = "";
            //        var address1 = "";
            //        var address2 = "";
            //        var city = "";
            //        var state = "";
            //        var PostalCode = "";
            //        var phone = "";

            //        if (newCustomer.Name) {
            //            companyName = newCustomer.Name;
            //        }

            //        if (newCustomer.Address1) {
            //            address1 = newCustomer.AddressLine1;
            //        }

            //        if (newCustomer.address2) {
            //            address1 = newCustomer.AddressLine2;
            //        }

            //        if (newCustomer.City) {
            //            city = newCustomer.City;
            //        }

            //        if (newCustomer.State) {
            //            state = newCustomer.State;
            //        }

            //        if (newCustomer.PostalCode) {
            //            PostalCode = newCustomer.PostalCode;
            //        }

            //        if (newCustomer.PhoneNumber) {
            //            phone = newCustomer.PhoneNumber;
            //        }

            //        var fields = this.$el.querySelectorAll('input[name]');

            //        for (var field of fields) {

            //            if (field.name === "BillingAddress.CompanyName") {
            //                field.value = companyName;
            //            }
            //            if (field.name === "BillingAddress.Line1") {
            //                field.value = address1;
            //            }
            //            if (field.name === "BillingAddress.Line2") {
            //                field.value = address2;
            //            }
            //            if (field.name === "BillingAddress.City") {
            //                field.value = city;
            //            }
            //            if (field.name === "BillingAddress.State") {
            //                field.value = state;
            //            }
            //            if (field.name === "BillingAddress.PostalCode") {
            //                field.value = PostalCode;
            //            }
            //            if (field.name === "BillingAddress.PhoneNumber") {
            //                field.value = phone;
            //            }
            //        }
            //    }
            //},
            selectedShipTo: function (newAddress, oldAddress) {
                console.log('filling in address', newAddress);
                if (newAddress) {
                    var companyName = "";
                    var address1 = "";
                    var address2 = "";
                    var city = "";
                    var state = "";
                    var postalCode = "";
                    var phone = "";

                    if (newAddress.CompanyName) {
                        companyName = newAddress.CompanyName;
                        this.model.ShippingAddress.CompanyName = newAddress.CompanyName;
                    }

                    if (newAddress.AddressLine1) {
                        address1 = newAddress.AddressLine1;
                        this.model.ShippingAddress.Line1 = newAddress.AddressLine1;
                    }

                    if (newAddress.AddressLine2) {
                        address2 = newAddress.AddressLine2;
                        this.model.ShippingAddress.Line2 = newAddress.AddressLine2;
                    }

                    if (newAddress.City) {
                        city = newAddress.City;
                        this.model.ShippingAddress.City = newAddress.City;
                    }

                    if (newAddress.State) {
                        state = newAddress.State;
                        this.model.ShippingAddress.State = newAddress.State;
                    }

                    if (newAddress.PostalCode) {
                        postalCode = newAddress.PostalCode;
                        this.model.ShippingAddress.PostalCode = newAddress.PostalCode;
                    }

                    if (newAddress.PhoneNumber) {
                        phone = newAddress.PhoneNumber;
                        this.model.ShippingAddress.PhoneNumber = newAddress.PhoneNumber;
                    }

                    var fields = this.$el.querySelectorAll('input[name]');

                    for (var field of fields) {

                        if (field.name === "ShippingAddress.CompanyName") {
                            field.value = companyName;
                        }
                        if (field.name === "ShippingAddress.Line1") {
                            field.value = address1;
                        }
                        if (field.name === "ShippingAddress.Line2") {
                            field.value = address2;
                        }
                        if (field.name === "ShippingAddress.City") {
                            field.value = city;
                        }
                        if (field.name === "ShippingAddress.State") {
                            field.value = state;
                        }
                        if (field.name === "ShippingAddress.PostalCode") {
                            field.value = postalCode;
                        }
                        if (field.name === "ShippingAddress.PhoneNumber") {
                            field.value = phone;
                        }
                    }
                }
            }
        },
        components: {
            checkoutNavigation,
            inputFieldAddress
        },
        methods: {
            submit: function(fieldName, doNotHighlight, callback) {
                var fields = this.$el.querySelectorAll('input[name], select[name]');
                var requestData = {};
                var store = this.$store;
                let loader = this.$loading.show();

                if (!callback) {
                    callback = function() {};
                }

                requestData['CustomerId'] = this.activeCustomer;

                for (var field of fields) {
                    if (field.type == 'checkbox' || field.type == 'radio') {
                        requestData[field.name] = field.checked;
                    } else {
                        requestData[field.name] = field.value;
                    }
                }

                this.$http.post(this.purl + '/uc/checkout/address', requestData).then((response) => {
                    if (response.data.Status && response.data.Status == 'failed') {

                        this.errorMessage = response.data.Message;
                        if (response.data.Data && response.data.Data.stacktrace) {
                            console.log(response.data.Data.stacktrace);
                        }
                        this.isError = true;

                        if (this.errorMessage) {
                            this.$toastify.toastError(this.errorMessage);
                        }
                    }

                    if (response.data) {
                        var data = response.data;
                        store.commit('update');
                        if (data.Status) {
                            if (fieldName && data.Data.errors) {
                                if (typeof data.Data.errors[fieldName] !== 'undefined' && data.Data.errors[fieldName].length > 0) {
                                    if (data.Data.errors && data.Data.errors[fieldName].length) {
                                        this.isError = true;
                                        loader.hide();
                                        callback(false, data.Data.errors[fieldName]);
                                    }
                                } else {
                                    callback(true, '');
                                }
                            } else if (data.Status == 'success') {
                                callback(true, '');
                            } else {
                                if (!doNotHighlight) {
                                    this.highlightFields(data.Data.errors);
                                }
                                this.isError = false;
                                loader.hide();
                                callback(false, data.Message);
                            }
                        } else {
                            loader.hide();
                            callback(false, '');
                        }
                    }
                });
            },
            continueFn: function (callback) {
                const sendingObj = { ...this.model.ShippingAddress, ShipToId: this.activeShipTo };
                this.$http.post(this.purl + '/uc/checkout/refreshshipping', sendingObj).then((response) => {
                    this.submit(null, false, callback);
                });
            },
            highlightFields: function(errors) {
                if (!errors || errors.length == 0) {
                    return;
                }

                for (var input of this.$children) {
                    input.errorMessage = errors[input.inputName]
                }
            },
          
        },
        created: function() {
            var scriptElement = rootElement.querySelector('script[purl]');
            this.purl = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).purl;
            this.$store.commit('vuecreated', 'address');
        
            this.$http.get(this.purl + '/uc/checkout/address', {}).then((response) => {
                if (response.data &&
                    response.data.Status &&
                    response.data.Status === 'success' &&
                    response.data.Data &&
                    response.data.Data.data) {

                    this.model = response.data.Data.data;


                    //this.selectedCustomer = this.availableCustomers.find(item => item.ErpId === this.activeCustomer);
                    //console.log('initiated customer', this.selectedCustomer);

                    if (!this.activeShipTo) {
                        this.$store.dispatch(INIT_SHIPTO_LIST, this.purl);
                    }

                    //now initialize address fields with current shipto on load
                    this.selectedShipTo = this.availableShipTos.find(item => item.ShipToId === this.activeShipTo);                  
                } else {
                    if (response.data.Status && response.data.Status === 'failed') {

                        this.errorMessage = response.data.Message;
                        if (response.data.Data && response.data.Data.stacktrace) {
                            console.log(response.data.Data.stacktrace);
                        }
                        this.isError = true;
                        this.$toastify.toastError(this.errorMessage);
                    }
                    this.model = null;
                }
            });

            triggerGoogleAnalyticsCheckout1Event();
        }
    });
}

// This is an awful way to handle race conditions
// We should switch to an Actor Model in the future
function triggerGoogleAnalyticsCheckout1Event() {
    if (globalThis.cart == null) {
        setTimeout(triggerGoogleAnalyticsCheckout1Event, 500);
    } else {
        var products = [];
        for (let i = 0; i < globalThis.cart.length; i++) {
            var product = globalThis.cart[i];
            products.push({
                id: product.Sku,
                name: product.ProductTitle,
                category: "",
                variant: product.VariantSku,
                brand: product.Brand,
                quantity: product.Quantity,
                dimension3: 'Ecommerce',
                metric5: 12,
                metric6: 1002
            });
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'eec.checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 1
                    },
                    products: products,
                }
            }
        });
    }
}
